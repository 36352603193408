import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {GrDownload} from "react-icons/gr";
import {useNavigate} from "react-router-dom";
import {useAxios} from "../../api/useAxios";
import {Colors} from "../../constants";
import {ILocation, IStaff} from "../../interface";
import CalenderButton, {
  CalenderMode,
} from "../../components/calendar-button/calendar-button";
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";
import Loader from "../../components/loader";
import WeeklyReport from "./layouts/weekly";
import moment, {Moment} from "moment";
import {useLocations} from "../../hooks/location";
import {useEmployees} from "../../hooks/employee";
import axiosInstance from "../../api/axiosInstance";

export default function DailyActivityReport({}) {
  const navigate = useNavigate();
  const [scheduleMode, setScheduleMode] = useState<CalenderMode>("Week");
  const [timePeriod, setTimePeriod] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({startDate: moment(), endDate: moment()});
  const [selectedLocationIndex, setSelectedLocationIndex] = useState<number>(0);

  const {locations} = useLocations();
  const {employees} = useEmployees();

  const [checkInHistory, setCheckInHistory] = useState<any>({});

  useEffect(() => {
    // console.log({ selectedLocationIndex });
    if (selectedLocationIndex > -1 && locations[selectedLocationIndex]?.id) {
      fetchCheckInHistory(locations[selectedLocationIndex]?.id);
    }
  }, [
    selectedLocationIndex,
    timePeriod.startDate,
    timePeriod.endDate,
    locations,
  ]);

  function fetchCheckInHistory(locId: number) {
    axiosInstance
      .get(
        `/comply-tsfm/api/reports/checkin?endDate=${timePeriod.endDate.format(
          "YYYY-MM-DD"
        )}&locationIdList=${locId}&startDate=${timePeriod.startDate.format(
          "YYYY-MM-DD"
        )}`
      )
      .then(({data}) => {
        let employeeWiseCheckin: any = {};
        for (let location of data?.locations) {
          for (let area of location?.areas) {
            for (let employee of area?.employees) {
              for (let shift of employee?.shifts) {
                employeeWiseCheckin[area.areaId] = {
                  [employee.id]: {
                    ...employeeWiseCheckin?.[area.areaId]?.[employee.id],
                    [shift.dated]: shift?.participation,
                  },
                };
              }
            }
          }
        }
        setCheckInHistory(employeeWiseCheckin);
      })
      .catch((err) => {
        console.log({err});
      });
  }
  // const [locations, setLocations] = useState<Array<ILocation>>();
  // const [staff, setStaff] = useState<any>({});

  // const locationRequest = useAxios(
  //   { url: "/comply-tsfm/api/location", method: "GET" },
  //   [],
  //   (data, error) => {
  //     if (data) {
  //       setLocations(data);
  //     }
  //   }
  // );

  // const staffRequest = useAxios(
  //   { url: "/comply-tsfm/api/employee" },
  //   [],
  //   (data, error) => {
  //     if (data) {
  //       let staff: any = {};
  //       for (let item of data) {
  //         staff[item.id] = item;
  //       }

  //       setStaff(staff);
  //     }
  //   }
  // );

  // if (locationRequest.loading && staffRequest.loading) {
  //   return <Loader />;
  // }

  return (
    <div
      className="white-container m-3 mx-2   d-none d-lg-block"
      style={{minWidth: "900px"}}
    >
      <Container fluid className="p-0 m-0">
        <Row
          className="p-0 m-0 align-items-center mb-3"
          // style={{ height: "10%" }}
        >
          <Col md="auto" className="p-1">
            <DropdownMenu
              noBorderRadius
              //   isSplitButton
              variant="cyan-6"
              label={
                // locationRequest.loading
                //   ? "Loading"
                //   :
                locations
                  ? locations?.[selectedLocationIndex]?.name
                  : "No Locations"
              }
              buttonProps={{
                style: {padding: "3px 5px", height: "100%", width: "100px"},
              }}
              menu={locations?.map(({id, name}, i) => ({
                id,
                label: name,
                value: id,
                callback: () => setSelectedLocationIndex(i),
              }))}
            />
          </Col>
          <Col md="auto" className="p-1">
            <CalenderButton
              // fontSize="10px"
              backgroundColor={Colors["cyan-6"]}
              style={{
                // fontSize: "1.2vh",
                padding: "3px 5px",
                height: "100%",
              }}
              mode={scheduleMode}
              onDateChanged={(timeperiod: any) => {
                setTimePeriod(timeperiod);
              }}
            />
          </Col>

          <Col md="auto" className="p-1">
            <DropdownMenu
              noBorderRadius
              isSplitButton
              variant="cyan-6"
              toggleVariant="cyan-6"
              label={`View: ${scheduleMode}`}
              buttonProps={{
                style: {padding: "3px 5px", height: "100%"},
              }}
              menu={[
                {
                  label: "Day",
                  value: "Day",
                  callback: () => setScheduleMode("Day"),
                },
                {
                  label: "Week",
                  value: "Week",
                  callback: () => setScheduleMode("Week"),
                },
                {
                  label: "2 Weeks",
                  value: "2 Weeks",
                  callback: () => setScheduleMode("2 Weeks"),
                },
                {
                  label: "Month",
                  value: "Month",
                  callback: () => setScheduleMode("Month"),
                },
              ]}
            />
          </Col>

          <Col className="d-flex justify-content-end">
            <Row className="justify-content-end">
              <Col md="auto" className="p-1">
                <DropdownMenu
                  fullWidth
                  variant="danger"
                  label="Export"
                  menu={[
                    {
                      label: "Download as CSV",
                      Icon: () => <GrDownload />,
                      callback: () => {},
                    },
                  ]}
                />
              </Col>
              <Col md="auto" className="p-1">
                <DropdownMenu
                  label="Options"
                  menu={[
                    {
                      label: "Edit Locations and Area",
                      callback: () => navigate("/app/location"),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="h-100">
          <WeeklyReport
            dateRange={timePeriod}
            dayCount={timePeriod.endDate.diff(timePeriod.startDate, "days") + 1}
            staff={employees}
            locationId={locations?.[selectedLocationIndex]?.id || 0}
            areas={locations?.[selectedLocationIndex]?.areas}
            checkinsEmployeeWise={checkInHistory}
          />
        </Row>
      </Container>
    </div>
  );
}
