import { Col, Container, Image, Row } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { ImUserPlus } from "react-icons/im";
import { Colors } from "../../constants";
import Text from "../text/text";
import "./employee-box.css";
interface IEmployeeBox {
  image?: string;
  isOpenShift?: boolean;
  preferredTitle?: string;
  isScheduleModeMonthly?: boolean;
  isSelected?: boolean;
}
export default function EmployeeBox({
  preferredTitle = "Open Shift",
  image,
  isOpenShift = false,
  isScheduleModeMonthly = false,
  isSelected,
}: IEmployeeBox) {
  return (
    <Container
      fluid
      className="employee-box-parent p-0 m-0 py-2 border-bottom"
      style={{
        backgroundColor: isSelected ? Colors["cyan-12"] : "transparent",
      }}
    >
      <Row className="justify-content-center align-items-center ">
        {!isScheduleModeMonthly && (
          <Col lg="auto" className="d-none d-lg-block">
            {image ? (
              <div style={{ width: "50px", height: "50px" }}>
                <Image
                  src={image}
                  roundedCircle
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: Colors.BLUE,
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  color: "white",
                }}
              >
                <FaUserAlt size={30} />
              </div>
            )}
          </Col>
        )}
        <Col lg={isScheduleModeMonthly ? 12 : 8}>
          <Text className="employee-box-name">{preferredTitle}</Text>
          <Text>0.00Hrs/$0.00</Text>
        </Col>
      </Row>
    </Container>
  );
}
