import moment, { Moment } from "moment";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { BsClockFill, BsFillCalendarWeekFill } from "react-icons/bs";
import { FaDollarSign, FaUserCircle } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";
import Text from "../../../../components/text/text";
import ScheduleModalProps from "./modal.props";
import Select, { StylesConfig } from "react-select";
import SelectTheme from "../../../../components-v2/Select/select-theme";
import { useLocations } from "../../../../hooks/location";
import { useEmployees } from "../../../../hooks/employee";
import { Fragment, MouseEventHandler, useEffect, useState } from "react";
import TimeInput from "../../../../components-v2/input/time-input";
import TextInput from "../../../../components-v2/input/input";
import Shifts, { IShift } from "../../../../modules/Shifts";
import { IEmployee } from "../../../../modules/Employee";
export type NewShiftDefaultValueType = Partial<Shifts.ShiftPayload> & {
  employeeObj?: { label?: string; value?: number };
  locationObj?: { label?: string; value?: number };
  areaObj?: { label?: string; value?: number };
};
interface NewShiftModalProps extends Omit<ScheduleModalProps, "onClose"> {
  date: Moment;
  onClose: (args: {
    action: "CREATE" | "CANCEL" | "UPDATE" | "DELETE";
    data?: any;
    shiftId?: number;
  }) => void;
  defaultValues?: Partial<Shifts.ShiftReponse>;
  action: "CREATE" | "UPDATE";
}
export default function NewShiftModal({
  onClose,
  show,
  date,
  defaultValues,
  action,
}: NewShiftModalProps) {
  const { locations } = useLocations();
  const { employees } = useEmployees();

  const COMMON_SELECT_STYLES: StylesConfig = {
    ...SelectTheme,
    control: (base, state) => ({
      ...base,
      ...(SelectTheme.control && SelectTheme.control(base, state)),
      fontSize: "12px",
    }),
    placeholder: (base, state) => ({
      ...base,
      ...(SelectTheme.placeholder && SelectTheme.placeholder(base, state)),
      fontSize: "10px",
    }),
    option: (base, state) => ({
      ...base,
      ...(SelectTheme.option && SelectTheme.option(base, state)),
      fontSize: "12px",
    }),
  };

  // const getEmployeesOptions = employees.map((employee) => ({
  //   label: employee.preferredTitle,
  //   value: employee.id,
  // }));
  // const getLocationOptions = locations.map((location) => ({
  //   label: location.name,
  //   value: location.id,
  // }));

  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
  } = useForm<Shifts.ShiftPayload>({
    defaultValues: {
      // areaId: defaultValues?.areaObj?.value ?? 0,
      employeeId: defaultValues?.employeeId,
      endTime: date
        .clone()
        .set({
          hours: 17,
        })
        .unix()
        .toString(),
      locationId: defaultValues?.locationId,
      mealBreak: 0,
      remarks: "",
      restBreak: 0,
      startDate: date.unix().toString(),
      startTime: date
        .clone()
        .set({
          hours: 9,
        })
        .unix()
        .toString(),
      areaList: defaultValues?.areas?.map((a) => a.areaId) ?? [],
      areas: defaultValues?.areas ?? [],
      // employee: { employee: "", id: 0, userId: 0 },
      employee: undefined,
      id: 0,
      location: undefined,
      ...defaultValues,
    },
  });

  const [shiftDuration, setShiftDuration] = useState("0hrs 0mins");
  useEffect(() => {
    const startTime = getValues("startTime");
    const endTime = getValues("endTime");

    if (startTime?.length > 0 && endTime?.length > 0) {
      const startMoment = moment.unix(Number(startTime));
      const endMoment = moment.unix(Number(endTime));
      const duration = moment.duration(endMoment.diff(startMoment));
      const hours = duration.hours();
      const minutes = duration.minutes();

      setShiftDuration(`${hours.toFixed(0)}hrs ${minutes.toFixed(0)}mins`);
    } else {
      setShiftDuration("0hrs 0mins");
    }
  }, [watch("startTime"), watch("endTime")]);

  function handleSave(data: Shifts.ShiftPayload) {
    delete data["areas"];
    delete data["employee"];
    delete data["location"];

    if (action === "CREATE") {
      delete data["id"];
      Shifts.createShift(
        data,
        (newShift) => {
          onClose({ action: "CREATE", data: newShift });
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (action === "UPDATE") {
      Shifts.updateShift(
        data,
        (newShift) => {
          onClose({ action: "UPDATE", data: newShift, shiftId: data.id });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  return (
    <Modal
      show={show}
      onHide={() => onClose({ action: "CANCEL" })}
      centered
      size="lg"
    >
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col lg={3} xs="12" className="my-auto">
              <Row xs="auto" lg="12" className="mb-3">
                <Col>
                  <LeftSectionIcon Icon={BsFillCalendarWeekFill} />
                </Col>
                <Col className="my-auto">
                  <Text style={{ fontWeight: "500", fontSize: "15px" }}>0</Text>
                  <Text>Weekly Hours</Text>
                </Col>
              </Row>
              <Row xs="auto" lg="12" className="mb-3">
                <Col>
                  <LeftSectionIcon Icon={FaDollarSign} />
                </Col>
                <Col className="my-auto">
                  <Text style={{ fontWeight: "500", fontSize: "15px" }}>0</Text>
                  <Text>Weekly Cost</Text>
                </Col>
              </Row>
              <Row xs="auto" lg="12" className="mb-3">
                <Col>
                  <LeftSectionIcon Icon={BsClockFill} />
                </Col>
                <Col className="my-auto">
                  <Text style={{ fontWeight: "500", fontSize: "13px" }}>
                    {/* {`${shiftLength.hours()} hrs ${shiftLength.minutes()} mins` ||
                      0} */}
                    {shiftDuration}
                  </Text>
                  <Text>Shift Length</Text>
                </Col>
              </Row>
              <Row xs="auto" lg="12" className="mb-3 ">
                <Col>
                  <LeftSectionIcon Icon={FaUserCircle} color="red" />
                </Col>
                <Col className="my-auto">
                  <Text style={{ fontWeight: "500", fontSize: "15px" }}>0</Text>
                  <Text>Shift Cost</Text>
                </Col>
              </Row>
            </Col>
            <Col xs="12" lg="auto" className="py-2">
              <div
                style={{
                  border: "1px solid lightgrey",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </Col>
            <Col xs="12" lg={8}>
              <Row className="align-items-center mb-4">
                <Col>
                  <Text style={{ fontSize: "20px" }}>
                    {action === "CREATE" ? "New" : "Update"} Shift on{" "}
                    {date.format("ddd Do MMM")}
                  </Text>
                </Col>
                <Col className="justify-content-end  d-flex" xs="auto">
                  <Button
                    variant="plain"
                    size="sm"
                    onClick={() => {
                      onClose({ action: "CANCEL" });
                      //   setShiftDetails(undefined);
                      //   setStartTime(undefined);
                      //   setEndTime(undefined);
                    }}
                  >
                    <RiCloseLine size={30} />
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Employee is required",
                      },
                      validate: (value) => {
                        return (value?.id ?? 0) > 0 || "Employee is required";
                      },
                    }}
                    control={control}
                    name="employee"
                    render={({ field }) => (
                      <Fragment>
                        <Select
                          styles={COMMON_SELECT_STYLES}
                          placeholder="Select Team Member"
                          options={employees.map((emp) => ({
                            userId: emp.id,
                            id: emp.id,
                            employee: emp.preferredTitle,
                          }))}
                          getOptionLabel={(op: any) => op?.employee ?? "label"}
                          getOptionValue={(op: any) =>
                            op?.id.toString() ?? "value"
                          }
                          {...field}
                          onChange={(val: any) => {
                            field.onChange(val);
                            setValue("employeeId", val?.id);
                          }}
                        />
                        {errors.employee && (
                          <Text
                            color="red"
                            fontSize="0.8rem"
                            style={{ minHeight: "0.3rem", marginTop: "0.5rem" }}
                          >
                            {errors.employee?.message}
                          </Text>
                        )}
                      </Fragment>
                    )}
                  />
                </Col>
                <Col>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Locations is required",
                      },
                    }}
                    control={control}
                    name="location"
                    render={({ field }) => (
                      <Fragment>
                        <Select
                          styles={COMMON_SELECT_STYLES}
                          placeholder="Select Location"
                          options={locations.map((loc) => ({
                            id: loc.id,
                            location: loc.name,
                          }))}
                          getOptionLabel={(op: any) => op.location}
                          getOptionValue={(op: any) => String(op.id)}
                          {...field}
                          onChange={(op: any) => {
                            field.onChange(op);
                            setValue("locationId", op.id);
                            setValue("areas", []);
                            // setValue("areaId", 0);
                          }}
                        />
                        {errors.location && (
                          <Text
                            color="red"
                            fontSize="0.8rem"
                            style={{ minHeight: "0.3rem", marginTop: "0.5rem" }}
                          >
                            {errors.location?.message}
                          </Text>
                        )}
                      </Fragment>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Area is required",
                      },
                      validate: (value) => {
                        return (
                          (value?.length ?? 0) > 0 || "Select Atleast One Area"
                        );
                      },
                    }}
                    control={control}
                    name="areas"
                    render={({ field }) => (
                      <Fragment>
                        <Select
                          isMulti
                          styles={COMMON_SELECT_STYLES}
                          placeholder="Select Area"
                          noOptionsMessage={() =>
                            getValues("location") !== undefined
                              ? "No Areas Found"
                              : "Select Location First"
                          }
                          options={locations
                            .find((l) => l.id === getValues("locationId"))
                            ?.areas?.map(
                              ({ id, name }) =>
                                ({
                                  areaId: id,
                                  area: name,
                                } as unknown as typeof field["value"])
                            )}
                          getOptionLabel={(op: any) => op.area}
                          getOptionValue={(op: any) => String(op.areaId)}
                          {...field}
                          onChange={(op: any) => {
                            field.onChange(op);
                            setValue(
                              "areaList",
                              op?.map((o: any) => o.areaId)
                            );
                          }}
                        />
                        {errors.areas && (
                          <Text
                            color="red"
                            fontSize="0.8rem"
                            style={{ minHeight: "0.3rem", marginTop: "0.5rem" }}
                          >
                            {errors.areas?.message}
                          </Text>
                        )}
                      </Fragment>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Start Time is required",
                      },
                    }}
                    control={control}
                    name="startTime"
                    render={({ field: { name, onBlur, onChange, value } }) => (
                      <TimeInput
                        onTimeChanged={(time) => {
                          console.log({ time });
                          if (time) {
                            if (action === "UPDATE") {
                              onChange(
                                time
                                  .set({
                                    date: date.date(),
                                  })
                                  .unix()
                                  .toString()
                              );
                            } else onChange(time.unix().toString());
                          } else {
                            onChange(undefined);
                          }
                        }}
                        label="Start"
                        timepickerProps={{
                          placeholder: "12:00 AM",
                          defaultValue: value
                            ? moment.unix(Number(value))
                            : undefined,
                        }}
                        isValid={errors[name] === undefined}
                        feedbackText={errors[name]?.message}
                      />
                    )}
                  />
                </Col>
                <Col>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "End Time is required",
                      },
                    }}
                    control={control}
                    name="endTime"
                    render={({ field: { name, onBlur, onChange, value } }) => (
                      <TimeInput
                        onTimeChanged={(time) => {
                          if (time) {
                            if (action === "UPDATE") {
                              onChange(
                                time
                                  .set({
                                    date: date.date(),
                                  })
                                  .unix()
                                  .toString()
                              );
                            } else onChange(time.unix().toString());
                          } else {
                            onChange(undefined);
                          }
                        }}
                        label="End"
                        timepickerProps={{
                          placeholder: "08:00 AM",
                          defaultValue: value
                            ? moment.unix(Number(value))
                            : undefined,
                        }}
                        isValid={errors[name] === undefined}
                        feedbackText={errors[name]?.message}
                      />
                    )}
                  />
                </Col>
                <Col>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Meal Break is required",
                      },
                    }}
                    control={control}
                    name="mealBreak"
                    render={({ field: { value, onChange, onBlur, name } }) => (
                      <TextInput
                        label="Meal Break (mins)"
                        controlProps={{
                          style: { fontSize: "13px" },
                          type: "number",
                          value,
                          onChange,
                          onBlur,
                        }}
                        isValid={errors[name] === undefined}
                        feedbackText={errors[name]?.message}
                      />
                    )}
                  />
                </Col>
                <Col>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Rest Break is required",
                      },
                    }}
                    control={control}
                    name="restBreak"
                    render={({ field: { value, onChange, onBlur, name } }) => (
                      <TextInput
                        label="Rest Break (mins)"
                        controlProps={{
                          style: { fontSize: "13px" },
                          type: "number",
                          value,
                          onChange,
                          onBlur,
                        }}
                        isValid={errors[name] === undefined}
                        feedbackText={errors[name]?.message}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: "Notes is required",
                      },
                    }}
                    control={control}
                    name="remarks"
                    render={({ field: { value, onChange, onBlur, name } }) => (
                      <TextInput
                        label="Notes"
                        placeholder="Add a note to this shift, the team member will be able to see your notes when they check their shift."
                        controlProps={{
                          style: { fontSize: "13px" },
                          as: "textarea",
                          value,
                          onChange,
                          onBlur,
                        }}
                        isValid={errors[name] === undefined}
                        feedbackText={errors[name]?.message}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {(getValues("id") ?? 0) > 0 && (
          <Button
            size="sm"
            variant="plain"
            className="px-4"
            style={{ color: "red" }}
            onClick={() => {
              Shifts.deleteShift(
                getValues("id")!,
                () => {
                  onClose({ action: "DELETE", shiftId: getValues("id") });
                },
                (error) => {}
              );
            }}
          >
            Delete
          </Button>
        )}
        <Button
          size="sm"
          variant="blue_color"
          className="px-4"
          onClick={handleSubmit(handleSave, (error) => {
            console.log(error);
          })}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const LeftSectionIcon = ({ Icon, color }: any) => (
  <div
    style={{ backgroundColor: "var(--bs-cyan-12)" }}
    className="p-3 rounded-circle d-flex justify-content-center align-items-center"
  >
    <Icon size={20} color={color || "var(--bs-gray-600)"} />
  </div>
);
