import {useEffect, useState} from "react";
import {Col, Container, Image, Row, Spinner} from "react-bootstrap";
import {FaBriefcaseMedical, FaRegUserCircle} from "react-icons/fa";
import {IoIosBusiness} from "react-icons/io";
import {VscWand} from "react-icons/vsc";
import {useLocation, useNavigate} from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import {useAxios} from "../../api/useAxios";
import Button from "../../components/button/Button";
import Text from "../../components/text/text";
import {Colors, localStorageKeys} from "../../constants";
import {useBusinessProfile} from "../../hooks/business";
import useModal from "../../hooks/modal";
import {useUserProfile} from "../../hooks/user";
import {IBusiness, IUser} from "../../interface";
import BusinessSettings from "../business-settings/business-settings";
import {CgLogOut} from "react-icons/cg";
import "./business-selection.scss";
import Divider from "../../components/divider";

export default function BusinessSelection({}) {
  const {state}: any = useLocation();
  console.log({state});

  const {showModal, closeModal} = useModal();
  const navigate = useNavigate();
  const {setUser} = useUserProfile();
  const {setBusiness} = useBusinessProfile();
  const [showBusinessSettingsModal, setshowBusinessSettingsModal] =
    useState(false);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);
  const [selectBusinessId, setSelectBusinessId] = useState<
    number | undefined
  >();
  const [refreshCount, setRefreshCount] = useState(0);
  const {title, email, profileUrl} = useUserProfile();
  const mainModal = useModal();
  const businessRequest = useAxios(
    {
      url: "/comply-tsfm/api/business",
      method: "GET",
    },
    [refreshCount],
    (data, error) => {
      if (error) {
        mainModal.showModal({APIType: "APIEXCEPTION", APIError: error});
      } else {
        setBusinesses(data);
      }
    }
  );

  function handleDelete(id: string) {
    axiosInstance
      .delete(`/comply-tsfm/api/business/${id}`)
      .then(({data}) => {
        setBusinesses(
          businesses.filter((business: any) => business?.id !== id)
        );
        closeModal();
      })
      .catch((error) => {
        mainModal.showModal({APIType: "APIEXCEPTION", APIError: error});
      });
  }
  useEffect(() => {
    localStorage.removeItem(localStorageKeys.BUSINESS);
  }, []);

  const [defaultBusinessSettings, setDefaultBusinessSettings] =
    useState<{[key: string]: {data: any}}>();

  useEffect(() => {
    let isMounted = true;
    const source = axiosInstance.CancelToken.source();
    if (selectBusinessId && isMounted) {
      axiosInstance
        .get(`/comply-tsfm/api/business/${selectBusinessId}`, {
          cancelToken: source.token,
        })
        .then(({data}) => {
          const {
            abn,
            acn,
            address,
            businessName,
            city,
            contactEmail,
            contactEmailVerified,
            contactName,
            contactPhone,
            contactPhoneVerified,
            country,
            id,
            postCode,
            website,
            customBranding,
            logoPath,
          } = data;

          setshowBusinessSettingsModal((prevState) => {
            setDefaultBusinessSettings({
              general: {
                data: {
                  abn,
                  acn,
                  address,
                  businessName,
                  city,
                  contactEmail,
                  contactEmailVerified,
                  contactName,
                  contactPhone,
                  contactPhoneVerified,
                  country,
                  id,
                  postCode,
                  website,
                },
              },
              branding: {
                data: {
                  customBranding,
                  logoPath,
                },
              },
            });
            return !prevState;
          });
        })
        .catch((error) => {
          showModal({APIType: "APIEXCEPTION", APIError: error});
        });
    }
    return () => {
      isMounted = false;
      source.cancel("Call ended");
      // setBusinessSettings({ general: { data: {} }, branding: { data: {} } });
    };
  }, [selectBusinessId]);

  return (
    <Container>
      <BusinessSettings
        // updateId={selectBusinessId}
        // key={moment().unix()}
        defaultValue={defaultBusinessSettings}
        show={showBusinessSettingsModal}
        onClose={(data: any) => {
          setBusinesses([...businesses, data]);
          setRefreshCount(refreshCount + 1);
          setSelectBusinessId(undefined);
          setshowBusinessSettingsModal(!showBusinessSettingsModal);
          setDefaultBusinessSettings(undefined);
        }}
        modalType={selectBusinessId ? "Update" : "Create"}
      />
      <Row className="mt-5">
        <Col>
          <Button
            variant="no_color"
            className="p-0 m-0"
            onClick={() => {
              localStorage.removeItem(localStorageKeys.USER);
              navigate("/");
            }}
          >
            <Text fontSize="18px" color={Colors.BLUE} icon={<CgLogOut />}>
              Logout
            </Text>
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto">
          <div
            className="white-container px-5 py-4 border rounded"
            style={{minWidth: "400px"}}
          >
            <div className="justify-content-center d-flex">
              {profileUrl ? (
                <Image
                  src={profileUrl}
                  roundedCircle
                  width="125vw"
                  height="125vh"
                />
              ) : (
                <FaRegUserCircle style={{width: "15vw", height: "15vh"}} />
              )}
            </div>
            <Text style={{textAlign: "center"}} fontSize="20px" bold>
              {title}
            </Text>
            <Text
              style={{textAlign: "center"}}
              className="mb-3"
              fontSize="15px"
              color="grey"
            >
              {email}
            </Text>

            <div className="grey-container rounded p-1 m-0 mt-4 mb-4">
              <Text
                className="text-center"
                icon={<VscWand style={{marginRight: 5}} />}
              >
                You can now update your{" "}
                <span style={{fontWeight: "bold"}}>preferred name</span> in your
                profile
              </Text>
            </div>
            <Button
              fullWidth
              noBorderRadius={false}
              className="mt-2"
              onClick={() => {
                navigate("/app/profile");
              }}
            >
              Edit Profile
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="mx-5 my-2 align-items-center ">
        <Col>
          <Text fontSize="22px" bold>
            Businesses
          </Text>
        </Col>
        <Col className="justify-content-end d-flex">
          <Button
            variant="plain"
            fontSize="13px"
            className="selection-new-business"
            noBorderRadius={false}
            textColor={Colors.BLUE}
            startIcon={<IoIosBusiness />}
            onClick={() => setshowBusinessSettingsModal(true)}
          >
            Add a new business
          </Button>
        </Col>
      </Row>
      {businessRequest.loading ? (
        <Row className="justify-content-center align-items-center">
          <Col xs="auto">
            <Spinner animation="border" />
          </Col>
          <Col xs="auto">
            <Text bold heading>
              Loading
            </Text>
          </Col>
        </Row>
      ) : businesses?.length > 0 ? (
        businesses?.map((data: any, i: number) => (
          <Row
            className="mx-5 border rounded my-2 business-container shadow-sm"
            key={i}
          >
            <Col className="white-container">
              <Row className="align-items-center">
                <Col>
                  <Text color={Colors.BLUE} fontSize="18px">
                    {data?.businessName}
                  </Text>
                  {/* <Text color="grey" fontSize="12px">
                    System Administrator
                  </Text> */}
                </Col>
                <Col>
                  <Row className="justify-content-end ">
                    {/* <Col xs="auto">
                      <Button noBorderRadius={false}>
                        Trial ends in 24 days. Subscribe Now
                      </Button>
                    </Col> */}
                    <Col xs="auto">
                      <Button
                        noBorderRadius={false}
                        variant="cyan-12"
                        onClick={() => {
                          // setshowBusinessSettingsModal((prevState) => {
                          setSelectBusinessId(data.id);
                          // return !prevState;
                          // });
                        }}
                      >
                        Settings
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Dropdown.Divider color="#e6e6e6" /> */}

              <Divider />
              <Row>
                <Col xs="auto">
                  <Text color="grey">{data?.numberOfLocations}</Text>
                  <Text color="grey">Locations</Text>
                </Col>
                <Col xs="auto">
                  <Text color="grey">{data?.numberOfPeople}</Text>
                  <Text color="grey">People</Text>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="auto">
                  <div
                    className="rounded-circle p-1"
                    style={{backgroundColor: "grey"}}
                  >
                    <Text
                      bold
                      fontSize="25px"
                      style={{width: "40px", height: "40px"}}
                      className="justify-content-center d-flex align-items-center"
                    >
                      {data?.businessName.charAt(0).toUpperCase()}
                    </Text>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col xs="auto">
                  <Button
                    variant="danger"
                    noBorderRadius={false}
                    onClick={() => {
                      showModal({
                        Component: (
                          <Text>{`Are you sure to delete "${data?.businessName}"?`}</Text>
                        ),
                        FooterComponent: (
                          <Row>
                            <Col>
                              <Button
                                onClick={() => {
                                  handleDelete(data?.id);
                                }}
                              >
                                Yes
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                onClick={() => {
                                  closeModal();
                                }}
                              >
                                No
                              </Button>
                            </Col>
                          </Row>
                        ),
                      });
                    }}
                  >
                    Delete
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={() => {
                      axiosInstance
                        .post("/comply-tsfm/api/business/select", {
                          businessId: data?.id,
                        })
                        .then((response) => {
                          if (state?.returnURL) {
                            window.location.replace(
                              `${state?.returnURL}&business=${data?.id}`
                            );
                          } else {
                            let user: any = localStorage.getItem(
                              localStorageKeys.USER
                            );
                            if (user) {
                              user = JSON.parse(user) as IUser;
                              user.roles = [response?.data?.access];
                              localStorage.setItem(
                                localStorageKeys.USER,
                                JSON.stringify(user)
                              );
                              setUser && setUser(user);
                            }
                            let business = businesses.filter(
                              (business) => business.id === data?.id
                            )?.[0];
                            localStorage.setItem(
                              localStorageKeys.BUSINESS,
                              JSON.stringify(business)
                            );
                            setBusiness(business);

                            navigate("/app/dashboard");
                          }
                        })
                        .catch((error) => {
                          showModal({
                            APIError: error,
                            APIType: "APIEXCEPTION",
                          });
                        });
                    }}
                    noBorderRadius={false}
                  >
                    Dashboard
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        ))
      ) : (
        <Row className="mt-5 justify-content-center">
          <Col xs="auto">
            <Text centered heading>
              No Businesses Registered
            </Text>
            <Button
              variant="plain"
              noBorderRadius={false}
              textColor={Colors.BLUE}
              startIcon={<FaBriefcaseMedical />}
              onClick={() => setshowBusinessSettingsModal(true)}
            >
              Add a new business
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
}
