import moment, { Moment } from "moment";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlinePlusSquare, AiTwotoneEdit } from "react-icons/ai";
import { BiPaste } from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import { IoIosCopy, IoMdCopy } from "react-icons/io";
import { RiListCheck } from "react-icons/ri";
import { TiPencil } from "react-icons/ti";
import { CalenderMode } from "../../../components/calendar-button/calendar-button";
import Text from "../../../components/text/text";
import { useUserProfile } from "../../../hooks/user";
import { ShiftType } from "../schedule";

import "./../schedule.scss";

interface IShiftBox {
  areaName?: string;
  locationName?: string;
  variant: ShiftType;
  isFortnightly?: boolean;
  time?: string;
  startTime: Moment;
  endTime: Moment;
  scheduleMode?: CalenderMode;
  onEdit?: Function;
  onCopy?: Function;
  backgroundColor?: string;
  onAdd?: Function;
  onPaste?: Function;
  copiedShift?: boolean;
}
export default function ShiftBox({
  areaName,
  locationName = "Sample Location",
  variant = "published",
  isFortnightly = false,
  time,
  endTime,
  startTime,
  scheduleMode,
  onEdit,
  onCopy,
  backgroundColor,
  onAdd,
  onPaste,
  copiedShift,
}: IShiftBox) {
  const user = useUserProfile();
  const isEmployer = !user.roles.includes("ROLE_CLIENT");
  const TIME_FORMAT = "hh:mma";
  const backgroundColors = {
    unallocated: "#e6e5e8",
    published: "#2ea147",
    unpublished: "#89878e",
    openshift: "#ba4993",
    warning: "#e0281c",
    "leave-approved": "#b1160b",
    "leave-pending": "#da928c",
    "people-unavailable": "#4a4463",
  };
  const isShiftUnallocated = variant === "unallocated";
  const textStyle: React.CSSProperties = {
    // color: isShiftUnallocated ? "black" : "white",
    fontWeight: "500",
  };

  return (
    <div
      className="position-relative shift-box"
      style={{ backgroundColor: backgroundColor || "green" }}
    >
      {isEmployer && (
        <div
          className={`shift-box-overlay ${
            scheduleMode === "Month" ? "flex-column" : ""
          }`}
          style={{ backgroundColor: backgroundColor || "green" }}
        >
          <div onClick={() => onAdd && onAdd()}>
            <AiOutlinePlusSquare className="shift-box-icon" />
          </div>
          <div
            onClick={() => {
              onEdit && onEdit();
            }}
          >
            <RiListCheck className="shift-box-icon" />
          </div>
          <div onClick={() => onCopy && onCopy()}>
            <FiCopy className="shift-box-icon" />
          </div>
          {copiedShift && (
            <div onClick={() => onPaste && onPaste()}>
              <BiPaste className="shift-box-icon" />
            </div>
          )}
        </div>
      )}
      <Container
        className="h-100 w-100 shift-box-wrapper d-flex justify-content-center align-items-center flex-column "
        fluid
        // style={{ backgroundColor: "red" }}
      >
        {isEmployer && (
          <Row>
            <Text
              centered
              fontSize={
                scheduleMode === "2 Weeks" || scheduleMode === "Month"
                  ? "9px"
                  : "12px"
              }
              style={textStyle}
            >
              {startTime.format(TIME_FORMAT)} - {endTime.format(TIME_FORMAT)}
            </Text>
          </Row>
        )}

        <Row>
          <Text
            centered
            fontSize={
              scheduleMode === "2 Weeks" || scheduleMode === "Month"
                ? "9px"
                : "12px"
            }
            style={textStyle}
          >
            {areaName}
          </Text>
        </Row>
        <Row>
          <Text
            centered
            fontSize={
              scheduleMode === "2 Weeks" || scheduleMode === "Month"
                ? "9px"
                : "12px"
            }
            style={textStyle}
          >
            {locationName}
          </Text>
        </Row>
      </Container>
    </div>
    //   <div
    //     className="position-relative shift-box w-100 h-100"
    //     // style={{ cursor: "pointer" }}
    //   >
    //     {!user.roles.includes("ROLE_CLIENT") && (
    //       <div className="options-overlay" style={{ backgroundColor }}>
    //         {scheduleMode === "Month" && (
    //           <Container fluid className="p-0 h-100">
    //             <Row className="m-auto h-100">
    //               <Col>
    //                 <div
    //                   className="shift-box-icon-container-monthly"
    //                   onClick={() => onAdd && onAdd()}
    //                 >
    //                   <AiOutlinePlusSquare />
    //                 </div>
    //               </Col>

    //               <Col>
    //                 <div
    //                   className="shift-box-icon-container-monthly"
    //                   onClick={() => onEdit && onEdit()}
    //                 >
    //                   <TiPencil />
    //                 </div>
    //               </Col>

    //               <Col>
    //                 <div
    //                   className="shift-box-icon-container-monthly"
    //                   onClick={() => onCopy && onCopy()}
    //                 >
    //                   <FiCopy />
    //                 </div>
    //               </Col>
    //             </Row>
    //           </Container>
    //         )}
    //         {(scheduleMode === "2 Weeks" || scheduleMode === "Week") && (
    //           <Container fluid className="h-100 ">
    //             <Row className="p-0 ">
    //               <Col xs="auto" className="p-0  ">
    //                 <div
    //                   className="shift-box-icon-container"
    //                   onClick={() => onAdd && onAdd()}
    //                 >
    //                   <AiOutlinePlusSquare size={15} />
    //                 </div>
    //               </Col>
    //               <Col xs="auto" className="p-0  ">
    //                 <div
    //                   className="shift-box-icon-container"
    //                   onClick={() => onEdit && onEdit()}
    //                 >
    //                   <AiTwotoneEdit size={15} />
    //                 </div>
    //               </Col>
    //               <Col xs="auto" className="p-0  ">
    //                 <div
    //                   className="shift-box-icon-container"
    //                   onClick={() => onCopy && onCopy()}
    //                 >
    //                   <FiCopy size={15} />
    //                 </div>
    //               </Col>
    //               <Col xs="auto" className="p-0 pe-3 ">
    //                 <div
    //                   className="shift-box-icon-container"
    //                   onClick={() => onPaste && onPaste()}
    //                 >
    //                   <BiPaste size={15} />
    //                 </div>
    //               </Col>
    //             </Row>
    //           </Container>
    //         )}
    //       </div>
    //     )}
    //     {scheduleMode === "Month" && (
    //       <div className="shift-box-wrapper">
    //         <Container
    //           fluid
    //           style={{ backgroundColor }}
    //           className="shift-box-parent p-0 w-100 h-100"
    //         >
    //           <Row>
    //             <Col className="px-3">
    //               {isEmployer && (
    //                 <>
    //                   <Text color="white" centered fontSize="9px">
    //                     {startTime.format(TIME_FORMAT)}
    //                   </Text>
    //                   <Text color="white" centered fontSize="9px">
    //                     -
    //                   </Text>
    //                   <Text color="white" centered fontSize="9px">
    //                     {endTime.format(TIME_FORMAT)}
    //                   </Text>
    //                 </>
    //               )}
    //               <Text centered color="white" fontSize="9px">
    //                 {areaName}
    //               </Text>
    //               <Text centered color="white" fontSize="9px">
    //                 {locationName}
    //               </Text>
    //             </Col>
    //           </Row>
    //         </Container>
    //       </div>
    //     )}
    //     {(scheduleMode === "2 Weeks" || scheduleMode === "Week") && (
    //       <div className="shift-box-wrapper">
    //         <Container
    //           fluid
    //           style={{
    //             backgroundColor: backgroundColor || backgroundColors[variant],
    //           }}
    //           className="shift-box-parent"
    //         >
    //           {isEmployer && (
    //             <Row>
    //               <Text
    //                 fontSize={isFortnightly ? "9px" : "12px"}
    //                 style={textStyle}
    //               >
    //                 {startTime.format(TIME_FORMAT)} -{" "}
    //                 {endTime.format(TIME_FORMAT)}
    //               </Text>
    //             </Row>
    //           )}
    //           <Row>
    //             <Text fontSize={isFortnightly ? "9px" : "12px"} style={textStyle}>
    //               {areaName}
    //             </Text>
    //           </Row>
    //           <Row>
    //             <Text fontSize={isFortnightly ? "9px" : "12px"} style={textStyle}>
    //               {locationName}
    //             </Text>
    //           </Row>
    //         </Container>
    //       </div>
    //     )}
    //   </div>
  );
}
