import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { BsCaretDownFill, BsPlusSquareDotted } from "react-icons/bs";
import { ImSearch } from "react-icons/im";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../../api/axiosInstance";
import { generateDateRange } from "../../../api/general";
import { useAxios } from "../../../api/useAxios";
import Button from "../../../components/button/Button";
import EmployeeBox from "../../../components/employee-box/employee";
import InputField from "../../../components/InputField/InputField";
import Loader from "../../../components/loader";
import Text from "../../../components/text/text";
import { Colors } from "../../../constants";
import { useLocations } from "../../../hooks/location";
import { IStaff } from "../../../interface";
import { IEmployee } from "../../../modules/Employee";

interface WeeklyReportProps {
  staff: Array<IEmployee>;
  checkinsEmployeeWise: any;
  dayCount: number;
  locationId: number;
  dateRange: { startDate: Moment; endDate: Moment };
  areas?: Array<{
    id: number;
    name: string;
  }>;
}

export default function WeeklyReport({
  staff,
  checkinsEmployeeWise,
  dayCount,
  locationId,
  dateRange,
  areas,
}: WeeklyReportProps) {
  const browserState = useLocation();
  const tableParentDivRef = useRef<HTMLDivElement>(null);
  const [numOfDays, setNumOfDays] = useState(7);
  const [timePeriod, setTimePeriod] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({ startDate: moment(), endDate: moment() });
  const [days, setDays] = useState<Array<Moment>>([]);
  const [searchText, setSearchText] = useState<string>();
  // const [areas, setAreas] = useState<any>({});

  const { locations } = useLocations();

  // const locationRequest = useAxios(
  //   { url: `/comply-tsfm/api/location/${locationId}`, method: "GET" },
  //   [locationId],
  //   (data, error) => {
  //     if (!error) {
  // let area: any = {};
  // for (let item of data?.area) {
  //   area[item.id] = item;
  // }

  //       setAreas(area);
  //     }
  //   }
  // );

  useEffect(() => {
    dateRange?.startDate &&
      setDays(generateDateRange(dateRange?.startDate.clone(), dayCount));
    if (dayCount) {
      setNumOfDays(dayCount);
    }
  }, [dateRange.startDate, dateRange.endDate, dayCount]);

  // if (locationRequest.loading) {
  //   return <Loader />;
  // }
  const columnWidth =
    (tableParentDivRef.current?.offsetWidth || 0) /
      (dateRange?.startDate ? numOfDays : 1) -
    200 / (dateRange.startDate ? numOfDays : 1);

  return (
    <div className="" ref={tableParentDivRef}>
      <Table responsive className="weekly-table ">
        <colgroup>
          <col style={{ width: 200, minWidth: 200 }} />
          {/* <col style={{ width: 100, minWidth: 100 }} /> */}
          {Array.from({ length: numOfDays || 0 }).map((_, index) => (
            <col
              style={{
                width: columnWidth,
                minWidth: columnWidth,
              }}
            />
          ))}
        </colgroup>
        <thead>
          <tr className="align-items-center">
            <th>
              <InputField
                fontSize="14px"
                placeHolder="Type to Search"
                backgroundColor="white"
                borderRadius="5px"
                startIcon={
                  <ImSearch color={Colors.HEX_TO_RGBA("#000000", 0.5)} />
                }
                wrapperClassName="mx-2 mb-2"
                inputClassName="p-1"
                onChangedText={setSearchText}
              />
            </th>
            {/* <th>
              <Text centered fontSize="11px" bold color={Colors.BLUE}>
                Area
              </Text>
            </th> */}
            {days.map((day, index) => (
              <th key={index}>
                <Text centered fontSize="11px" bold color={Colors.BLUE}>
                  {day.format("ddd")}
                </Text>
                <Text centered fontSize="11px" bold color={Colors.BLUE}>
                  {day.format("Do")}
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {areas?.map((area, i) => (
            <>
              <tr style={{ height: 1 }}>
                <td colSpan={numOfDays + 1} className="area-name-td">
                  {area?.name}
                </td>
              </tr>
              {Object.entries(staff).map(
                ([staffId, { preferredTitle, id }]) => (
                  <tr>
                    <td className="first-col">
                      <EmployeeBox
                        // image="https://picsum.photos/200/300"
                        preferredTitle={preferredTitle}
                        isScheduleModeMonthly={false}
                      />
                    </td>

                    {days?.map((day, index) => {
                      return (
                        <td key={index} style={{ height: "inherit" }}>
                          {checkinsEmployeeWise.hasOwnProperty(area.id) &&
                            checkinsEmployeeWise[area.id].hasOwnProperty(id) &&
                            checkinsEmployeeWise[area.id][id].hasOwnProperty(
                              day.format("DD/MM/YYYY")
                            ) &&
                            Object.values(
                              checkinsEmployeeWise[area.id][id][
                                day.format("DD/MM/YYYY")
                              ]
                            )?.map((checkin: any, i) => {
                              return (
                                <Text>{`${i + 1}. ${moment
                                  .unix(Number(checkin?.startTime))
                                  .format("HH:mm a")}`}</Text>
                              );
                            })}
                        </td>
                      );
                    })}

                    {/* {checkinsEmployeeWise.hasOwnProperty(areaId) &&
                      checkinsEmployeeWise[areaId].hasOwnProperty(id) &&
                      Object.values(checkinsEmployeeWise[areaId][id])?.map(
                        (checkin: any) => (
                          <td style={{ height: "inherit" }}>
                            <Text>{JSON.stringify(checkin)}</Text>
                          </td>
                        )
                      )} */}
                  </tr>
                )
              )}
            </>
          ))}

          {/* {Object.entries(staff)?.map(([key, { preferredTitle, id }], i) => (
            <tr style={{ height: 1 }}>
              <td className="first-col">
                <EmployeeBox
                  image="https://picsum.photos/200/300"
                  preferredTitle={preferredTitle}
                  isScheduleModeMonthly={false}
                />
              </td>

              {days?.map((day, index) => (
                <td key={index} style={{ height: "inherit" }}>
                  {checkinsEmployeeWise.hasOwnProperty(id) &&
                    Object.entries(checkinsEmployeeWise[id])?.map(
                      ([key, value]) => <Text>{key}</Text>
                    )}
                </td>
              ))}
            </tr>
          ))} */}
        </tbody>
      </Table>
    </div>
  );
}
