import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { removeEmptyOrNull } from "../../../api/general";
import CalendarInput from "../../../components/calendar-input/calendar-input";
import InputField from "../../../components/InputField/InputField";
import RadioBtn, { CheckBoxes } from "../../../components/radio/radio";
import Text from "../../../components/text/text";

interface IEmployeeInfo {
  onUpdate: (data: any) => void;
  defaultValue: any;
}
export default function EmployeeInfo({
  defaultValue,
  onUpdate,
}: IEmployeeInfo) {
  const [contractor, setIsContractor] = useState<boolean>();
  const [firstName, setFirstName] = useState<string>(defaultValue?.firstName);
  const [lastName, setLastName] = useState<string>(defaultValue?.lastName);
  const [email, setEmail] = useState<string>(defaultValue?.email);
  const [phone, setPhone] = useState<string>(defaultValue?.contactNumber);
  const [address, setAddress] = useState<string>(defaultValue?.address);
  const [position, setPosition] = useState<string>(defaultValue?.position);
  const [dateOfBirth, setDateOfBirth] = useState<string>(
    defaultValue?.dateOfBirth
  );
  const [startDate, setStartDate] = useState<string>(defaultValue?.startDate);
  const [endDate, setEndDate] = useState<string>(defaultValue?.endDate);
  const [tfn, setTfn] = useState<string>(defaultValue?.tfn);
  const [abn, setAbn] = useState<string>(defaultValue?.abn);
  const [access, setAccess] = useState<string>(defaultValue?.accessKey);
  const [fullyTrained, setfullyTrained] = useState<boolean>(
    defaultValue?.fullyTrained
  );

  const [modules, setModules] = useState<Array<string>>(defaultValue?.modules);

  useEffect(() => {
    onUpdate(
      removeEmptyOrNull({
        modules,
        contractor,
        firstName,
        lastName,
        position,
        phone,
        dateOfBirth,
        startDate,
        endDate,
        tfn,
        abn,
        access,
        fullyTrained,
        email,
        address,
        contactNumber: phone,
      })
    );
  }, [
    contractor,
    firstName,
    lastName,
    position,
    phone,
    dateOfBirth,
    startDate,
    endDate,
    tfn,
    abn,
    access,
    fullyTrained,
    modules,
  ]);

  const employeeFields = [
    {
      placeholder: "First Name",
      onChange: setFirstName,
      defaultValue: defaultValue?.firstName,
      value: firstName,
    },
    {
      placeholder: "Last Name",
      onChange: setLastName,
      defaultValue: defaultValue?.lastName,
      value: lastName,
    },
    {
      label: "Position",
      options: [
        {
          label: "Full Time",
          value: "full-time",
        },
        {
          label: "Part Time",
          value: "part-time",
        },
        {
          label: "Casual",
          value: "casual",
        },
      ],
      isRadio: true,
      onChange: setPosition,
      defaultValue: defaultValue?.position,
      value: "",
    },
    {
      placeholder: "DOB",
      isDateInput: true,
      onChange: (date: any) => {
        setDateOfBirth(date?.format("YYYY-MM-DD"));
      },
      defaultValue: defaultValue?.dateOfBirth,
      value: dateOfBirth,
      calenderProps: {
        maximumDate: {
          day: moment().day(),
          month: moment().month() + 1,
          year: moment().subtract(16, "years").year(),
        },
      },
    },
    {
      placeholder: "Address",
      onChange: (address: any) => {
        setAddress(address?.address);
      },
      isAddressInput: true,
      defaultValue: defaultValue?.address,
      value: address,
    },
    // {
    //   placeholder: "Email",
    //   onChange: setEmail,
    // },
    // {
    //   placeholder: "Password",
    // },
    {
      placeholder: "Mobile",
      onChange: setPhone,
      defaultValue: defaultValue?.contactNumber,
      value: phone,
    },
    {
      placeholder: "Date of joining",
      isDateInput: true,
      onChange: (date: any) => {
        setStartDate(date?.format("YYYY-MM-DD"));
      },
      defaultValue: defaultValue?.startDate,
      value: startDate,
    },
    {
      placeholder: "Termination",
      isDateInput: true,
      onChange: (date: any) => {
        setEndDate(date?.format("YYYY-MM-DD"));
      },
      defaultValue: defaultValue?.endDate,
      value: endDate,
    },
    {
      placeholder: "TFN",
      onChange: setTfn,
      defaultValue: defaultValue?.tfn,
      value: tfn,
    },
    {
      placeholder: "ABN",
      onChange: setAbn,
      defaultValue: defaultValue?.abn,
      value: abn,
    },
    {
      label: "Access",
      options: [
        {
          label: "Employee",
          value: "ROLE_EMPLOYEE",
        },
        {
          label: "Employer",
          value: "ROLE_EMPLOYER",
        },

        {
          label: "Client",
          value: "ROLE_CLIENT",
        },
      ],
      isRadio: true,
      onChange: setAccess,
      defaultValue: defaultValue?.accessKey,
      value: "",
    },
    {
      label: "Modules",
      options: [
        {
          label: "Auditor",
          value: "AUDITOR",
        },
        {
          label: "Timesheet",
          value: "TIMESHEET",
        },
      ],
      isCheckbox: true,
      onChange: setModules,
      defaultValue: defaultValue?.modules,
      value: "",
    },
    {
      label: "If he fully Trained?",
      options: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      isRadio: true,
      onChange: setfullyTrained,
      defaultValue: defaultValue?.fullyTrained,
      value: "",
    },
    {
      label: "Is he a contractor?",
      options: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      isRadio: true,
      onChange: (value: boolean) => {
        setIsContractor(value);
      },
      defaultValue: defaultValue?.contractor,
      value: "",
    },
  ];

  return (
    <Container>
      <Row className="align-items-center">
        <Col>
          <Text heading bold fontSize={16}>
            Employee Information
          </Text>
        </Col>
        <Col>
          <InputField
            placeHolder="Email"
            // onChangedText={setEmail}
            inputProps={{
              defaultValue: defaultValue?.email,
              value: email,
              onChange: (e) => {
                setEmail(e.target.value);
              },
            }}
            //   isDateInput={isDateInput}
            fontSize={14}
          />
        </Col>
      </Row>
      <Row>
        {employeeFields.map(
          (
            {
              isRadio,
              isDateInput,
              label,
              onChange,
              options,
              placeholder,
              isAddressInput,
              defaultValue,
              value,
              calenderProps,
              isCheckbox,
            },
            i
          ) => (
            <Col xs="12" md="12" lg="4" className="my-2" key={i}>
              {isRadio ? (
                <RadioBtn
                  options={options}
                  onChange={onChange}
                  label={label}
                  inline
                  groupName={label}
                  defaultValue={defaultValue}
                  labelProps={{ fontSize: 14 }}
                  isCheckbox={isCheckbox}
                  // valueToCheckWithDefaultValue={label}
                />
              ) : isDateInput ? (
                <CalendarInput
                  onDateChanged={onChange}
                  inputProps={{
                    placeHolder: placeholder,
                    label,
                    fullwidth: true,
                    inputProps: { defaultValue, value },
                    fontSize: "14px",
                  }}
                  calendarProps={calenderProps}
                />
              ) : isCheckbox ? (
                <CheckBoxes
                  options={options}
                  onChange={onChange}
                  label={label}
                  inline
                  groupName={label}
                  defaultValue={defaultValue}
                  labelProps={{ fontSize: 14 }}
                />
              ) : (
                <InputField
                  placeHolder={placeholder}
                  onChangedText={onChange}
                  label={label}
                  isAddressInput={isAddressInput}
                  fontSize="14px"
                  inputProps={{ defaultValue, value }}
                />
              )}
            </Col>
          )
        )}
      </Row>
    </Container>
  );
}
